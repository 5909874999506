<template>
  <div class="view-root">
    <h3>订单列表</h3>
    <!-- <div style="text-align: right">
      <el-button type="primary" size="small" @click="editGuestInfo(null)"
        >添加订单</el-button
      >
    </div> -->
    <el-table :data="dataList" style="margin-top: 15px">
      <el-table-column type="index" prop="index" label="序号"></el-table-column>
      <el-table-column prop="organizer_name" label="客户名称"></el-table-column>
      <el-table-column prop="order_type" label="类型"></el-table-column>
      <el-table-column prop="order_amount" label="订单量"></el-table-column>
      <el-table-column prop="order_price" label="金额"></el-table-column>
      <el-table-column prop="order_currency" label="货币"></el-table-column>
      <el-table-column prop="order_status" label="订单状态"></el-table-column>
      <el-table-column prop="" label="开票状态">
        <template slot-scope="scope">
          {{ scope.row.invoice_status == '未支付' ? '' : scope.row.invoice_status }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="editInfo(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="showInfoDialog" :title="info.id == '' ? '添加订单' : '编辑订单'">
      <div>
        <el-form :model="info" :rules="rules" ref="guestInfo">
          <el-form-item label="客户" prop="organizer_name">
            <el-input disabled v-model="info.organizer_name"></el-input>
          </el-form-item>

          <el-form-item label="订单类型" prop="order_type">
            <el-select v-model="info.order_type" placeholder="请选择">
              <el-option v-for="item in ['按人次', '按会议']" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="订单量" prop="order_amount">
            <el-input type="number" v-model="info.order_amount"></el-input>
          </el-form-item>

          <el-form-item label="金额" prop="order_price">
            <el-input type="number" v-model="info.order_price"></el-input>
          </el-form-item>

          <el-form-item label="货币" prop="order_currency">
            <el-select v-model="info.order_currency" placeholder="请选择">
              <el-option v-for="item in ['人民币']" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="订单状态" prop="order_status">
            <el-select v-model="info.order_status" placeholder="请选择">
              <el-option v-for="item in ['待支付', '已取消', '已支付']" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发票状态" prop="invoice_status">
            <el-select v-model="info.order_status" placeholder="请选择">
              <el-option v-for="item in ['未支付', '待开具', '已开具']" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

        </el-form>
        <div style="text-align: right">
          <span slot="footer" class="dialog-footer">
            <el-button @click="showInfoDialog = false">取 消</el-button>
            <el-button type="primary" @click="saveInfo()">确 定</el-button>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getQiniuToken,
  postRequest,
} from "../../api/api";
import "../../assets/common/common.css";
import * as qiniu from "qiniu-js";
import VueTagsInput from "@johmun/vue-tags-input";
export default {
  name: "index",
  components: {
    VueTagsInput,
  },
  data() {
    return {
      // session_id: this.$route.query.session_id,
      dataList: [],
      tag: "",
      tags: [],
      info: this.initInfo(),
      showInfoDialog: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    initInfo() {
      return {
        id: "",
        'organizer_id': '',
        'order_amount': '',//按人次 是用户具体的数量，按会议 是单个MEETING最大的CAP
        'order_type': '',//按人次, 按会议。按人次 适合小型会议，按会议 适合大型会议
        'order_currency': '',//默认RMB
        'order_price': '',//int
        'order_status': '',//待支付, 已取消, 已支付, 
        'invoice_status': '',//未支付, 待开具, 已开具, 
        'use_status': '',//待生效, 已生效
        'payment_method': '',//微信支付, 支付宝, 银行转账
        'payment_info': '',//支付的回调信息
        'payment_time': '',//支付的时间
      }
    },
    fetchData() {
      var params = {};
      params['function'] = 'getOrders';
      postRequest(params).then((res) => {
        this.dataList = res.data.data;
      });
    },
    editInfo(row) {
      if (row) {
        this.info = row;
      } else {
        this.info = initInfo();
      }
      this.showInfoDialog = true;
    },
    saveInfo() {
      this.info['function'] = 'updateOrganizerOrder';
      postRequest(this.info).then((res) => {
        this.$message.success("保存成功");
        this.fetchData();
        this.showInfoDialog = false;
      });
    },
  },
};
</script>
<style>
.ql-container {
  height: 160px !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
